import { createSlice } from "@reduxjs/toolkit";

const initialState = {
  roomsList: [],
  roomDetail: null,
  roomsBookingRestrictionList: [],
  roomsBookingRestrictionDetail: null,
  roomsCalenderList: [],
  cancellationPolicyList: [],
  refreshPolicy: false,

};
const roomSlice = createSlice({
  name: "room",
  initialState: initialState,
  reducers: {
    roomListSuccess: (state, action) => {
      state.roomsList = action.payload;
    },
    roomListFailed: (state, action) => {
      state.roomsList = [];
    },
    roomCalenderListSuccess: (state, action) => {
      state.roomsCalenderList = action.payload;
    },
    roomCalenderListFailed: (state, action) => {
      state.roomsCalenderList = [];
    },
    roomsBookingRestrictionListSuccess: (state, action) => {
      state.roomsBookingRestrictionList = action.payload;
    },
    roomsBookingRestrictionListFailed: (state, action) => {
      state.roomsBookingRestrictionList = [];
    },
    cancellationPolicyListSuccess: (state, action) => {
      state.cancellationPolicyList = action.payload;
    },
    cancellationPolicyListFailed: (state, action) => {
      state.cancellationPolicyList = [];
    },
    roomDetailSuccess: (state, action) => {
      state.roomDetail = action.payload;
    },
    roomDetailFailed: (state, action) => {
      state.roomDetail = null;
    },
    refreshPolicySuccess: (state, action) => {
      state.refreshPolicy = true;
    },
    refreshPolicyFailed: (state, action) => {
      state.refreshPolicy = false;
    },
    roomsBookingRestrictionDetailSuccess: (state, action) => {
      state.roomsBookingRestrictionDetail = action.payload;
    },
    roomsBookingRestrictionDetailFailed: (state, action) => {
      state.roomsBookingRestrictionDetail = null;
    },
  },
});

export const {
  roomListSuccess,
  roomListFailed,
  roomCalenderListSuccess,
  roomCalenderListFailed,
  roomDetailSuccess,
  roomDetailFailed,
  roomsBookingRestrictionDetailSuccess,
  roomsBookingRestrictionDetailFailed,
  roomsBookingRestrictionListSuccess,
  roomsBookingRestrictionListFailed,
  cancellationPolicyListSuccess,
  cancellationPolicyListFailed,
  refreshPolicy,
  refreshPolicySuccess,
  refreshPolicyFailed
  

} = roomSlice.actions;
export default roomSlice.reducer;
