import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import Loader from "../../layout/Loader";
import {
  FormControl,
  Grid,
  IconButton,
  InputAdornment,
  MenuItem,
  Select,
  TextField,
  Typography,
} from "@mui/material";
import SearchIcon from "@mui/icons-material/Search";
import { DataGrid } from "@mui/x-data-grid";
import { rowsPerPageJsonData } from "../../utils/JsonData";
import moment from "moment";
import { use } from "i18next";
import { fetchList } from "../../apis/services/CommonApiService";
import { ApiEndPoints } from "../../apis/ApiEndPoints";
import { getLanguageCode } from "../../utils/helper";

const OtaLogs = () => {
  const { t, i18n } = useTranslation();

  const [datagridOptions, setDatagridOptions] = useState({
    loading: false,
    rows: [],
    totalRows: 0,
    rowsPerPageOptions: rowsPerPageJsonData,
    pageSize: 10,
    page: 1,
  });
  const [loading, setLoading] = useState(false);

  const datagridColumns = [
    { field: "date", headerName: t("Date/Time"), flex: 1.5 },
    { field: "startDate", headerName: t("Start Date"), flex: 1 },
    { field: "endDate", headerName: t("End Date"), flex: 1 },
    { field: "log_type", headerName: t("Log Type"), flex: 1 },
    { field: "rate_type", headerName: t("Rate Type"), flex: 1 },
    { field: "room_type", headerName: t("Room Category"), flex: 1 },
    { field: "description", headerName: t("Description"), flex: 1.5 },
    // { field: "ota", headerName: t("OTA"), flex: 1 },
    { field: "user", headerName: t("User"), flex: 1 },
  ];

  const [searchFilters, setSearchFilters] = useState({
    searchText: "",
    startDate: moment().subtract(7, "days").format("YYYY-MM-DD"),
    endDate: moment().format("YYYY-MM-DD"),
    log_type: 3,
    room_type: 0,
  });

  const updatedatagridOptions = (k, v) =>
    setDatagridOptions((prev) => ({ ...prev, [k]: v }));

  const getOtaLogs = useCallback(async () => {
    updatedatagridOptions("loading", true);
    const result = await fetchList(
      ApiEndPoints.GET_OTA_LOGS +
        `?page_no=${datagridOptions.page}&items_per_page=${
          datagridOptions.pageSize
        }&start_date=${searchFilters.startDate}&end_date=${
          searchFilters?.endDate +
          (searchFilters?.log_type === 3
            ? ""
            : "&log_type=" + searchFilters?.log_type)
        }&room_type=${searchFilters?.room_type}`
    );

    if (result?.status === 200) {
      updatedatagridOptions("totalRows", result.data.totalRows);
      const ratesDatagridRows = result?.data?.rows?.map((item, index) => {
        return {
          id: item?.id,
          date:
            moment.utc(item?.date, "YYYY-MM-DD").local().format("MM/DD/YYYY") +
            " " +
            moment.utc(item?.time, "hh:mm:ss").local().format("hh:mm A"),
          log_type: item?.log_type === 0 ? "Inventory Change" : "Rate Change",
          rate_type:
            item?.type === 0 ? "Central" : "Ota-" + item?.otaInfo?.ota_name,
          room_type: item?.roomTypeInfo?.roomcategory_name,
          description: item?.changes_made,
          ota: item?.ota_code === 0 ? "" : item?.otaInfo?.ota_name,
          user: item?.user,
          startDate: moment(item?.start_date, "YYYY-MM-DD").format(
            "MM/DD/YYYY"
          ),
          endDate: moment(item?.end_date, "YYYY-MM-DD").format("MM/DD/YYYY"),
        };
      });
      updatedatagridOptions("rows", ratesDatagridRows);
      updatedatagridOptions("loading", false);
    } else {
      updatedatagridOptions("totalRows", 0);
      updatedatagridOptions("rows", []);
      updatedatagridOptions("loading", false);
    }
  }, [
    datagridOptions.page,
    datagridOptions.pageSize,
    searchFilters.log_type,
    searchFilters.room_type,
    searchFilters.endDate,
    searchFilters.startDate,
  ]);

  useEffect(() => {
    getOtaLogs();
  }, [getOtaLogs]);

  const [roomCategoryTypes, setRoomCategoryTypes] = useState([]);

  const getRoomData = async () => {
    // setLoading(true)
    const resultRe = await fetchList(ApiEndPoints.ROOM_CATEGORY_SELECT_LIST);
    const RoomData = [];
    if (resultRe?.status === 200) {
      for (var j in resultRe.data) {
        RoomData.push({
          id: resultRe.data[j].id,
          type: resultRe.data[j]?.room_type_short_code,
          type_id: resultRe.data[j].id,
          max_occupancy: resultRe.data[j]?.max_occupancy,
        });
      }
      if (!(JSON.stringify(RoomData) === JSON.stringify(roomCategoryTypes))) {
        setRoomCategoryTypes(RoomData);
      }
    } else {
      setRoomCategoryTypes();
    }
  };

  useEffect(() => {
    getRoomData();
  }, []);

  const handleSearch = (event) => {
    const { value } = event.target;
    if (value.length > 3) {
      setSearchFilters((prev) => ({
        ...prev,
        searchText: value,
      }));
    } else if (value.length == 0) {
      setSearchFilters((prev) => ({
        ...prev,
        searchText: value,
      }));
    }
  };

  return (
    <div className="main-layout-background h-100vh w-100">
      {loading && <Loader />}
      <div
        className="d-flex mb-2  justify-content-between align-items-center"
        style={{ gap: "12px" }}
      ></div>
      <Grid container spacing={2} alignItems="flex-start">
        <Grid item lg={3} md={3} sm={3} xs={3} style={{ marginBottom: "20px" }}>
          <FormControl fullWidth>
            <Typography
              id="font-600"
              style={{
                marginBottom: "11px",
                color: "rgb(30, 30, 30)",
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: "500 !important",
              }}
            >
              {t("Start Date")}
            </Typography>
            <TextField
              type="date"
              value={searchFilters.startDate}
              onChange={(e) => {
                setSearchFilters((prevState) => ({
                  ...prevState,
                  startDate: e.target.value,
                  endDate: moment(
                    moment(e.target.value, "YYYY-MM-DD").add({ days: 7 })
                  ).format("YYYY-MM-DD"),
                }));
              }}
            />
          </FormControl>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3} style={{ marginBottom: "20px" }}>
          <FormControl fullWidth>
            <Typography
              id="font-600"
              style={{
                marginBottom: "11px",
                color: "rgb(30, 30, 30)",
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: "500 !important",
              }}
            >
              {t("End Date")}
            </Typography>
            <TextField
              type="date"
              value={searchFilters.endDate}
              onChange={(e) => {
                setSearchFilters((prevState) => ({
                  ...prevState,
                  endDate: e.target.value,
                }));
              }}
              inputProps={{
                max: moment(
                  moment(searchFilters.startDate, "YYYY-MM-DD").add({
                    days: 89,
                  })
                ).format("YYYY-MM-DD"),
              }}
            />
          </FormControl>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <FormControl fullWidth>
            <Typography
              id="font-600"
              style={{
                marginBottom: "11px",
                color: "rgb(30, 30, 30)",
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: "500 !important",
              }}
            >
              {t("Room Type")}
            </Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={searchFilters?.room_type}
              //label="Room Status"

              onChange={(e) =>
                setSearchFilters({
                  ...searchFilters,
                  room_type: e.target.value,
                })
              }
            >
              <MenuItem value={0}>{t("Select Room Type")}</MenuItem>
              {roomCategoryTypes?.map((data) => (
                <MenuItem value={data.id} key={data.type}>
                  {data.type}
                </MenuItem>
              ))}
            </Select>
          </FormControl>
        </Grid>
        <Grid item lg={3} md={3} sm={3} xs={3}>
          <FormControl fullWidth>
            <Typography
              id="font-600"
              style={{
                marginBottom: "11px",
                color: "rgb(30, 30, 30)",
                fontSize: "16px",
                lineHeight: "24px",
                fontWeight: "500 !important",
              }}
            >
              {t("Log Type")}
            </Typography>
            <Select
              labelId="demo-simple-select-label"
              id="demo-simple-select"
              value={searchFilters?.log_type}
              //label="Room Status"

              onChange={(e) =>
                setSearchFilters({
                  ...searchFilters,
                  log_type: e.target.value,
                })
              }
            >
              <MenuItem value={3}>{t("Select Log Type")}</MenuItem>
              <MenuItem value={0}>{t("Inventory Change")}</MenuItem>
              <MenuItem value={1}>{t("Rate Change")}</MenuItem>
            </Select>
          </FormControl>
        </Grid>
      </Grid>
      <div className="w-100">
        <DataGrid
          localeText={
            getLanguageCode().components.MuiDataGrid.defaultProps.localeText
          }
          density="compact"
          autoHeight
          getRowHeight={() => "auto"}
          pagination
          paginationMode="server"
          loading={datagridOptions.loading}
          rowCount={datagridOptions.totalRows}
          rowsPerPageOptions={datagridOptions.rowsPerPageOptions}
          // rows={ratesDatagridRows}
          rows={datagridOptions.rows}
          columns={datagridColumns}
          page={datagridOptions.page - 1}
          pageSize={datagridOptions.pageSize}
          //   checkboxSelection={true}
          sx={{
            "& .MuiDataGrid-cell": {
              padding: "10px 5px!important",
            },
          }}
          onPageChange={(newPage) => {
            setDatagridOptions((old) => ({
              ...old,
              page: newPage + 1,
            }));
          }}
          onPageSizeChange={(pageSize) => {
            console.log("page size", pageSize);
            updatedatagridOptions("page", 1);
            updatedatagridOptions("pageSize", pageSize);
          }}
        />
        {/* </div> */}
      </div>
    </div>
  );
};

export default OtaLogs;
