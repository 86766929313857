import {
  Avatar,
  Breakpoint,
  Dialog,
  DialogTitle,
  IconButton,
  PaperProps,
  Typography,
} from "@mui/material";
import React from "react";
import images from "../../assets/images";

interface IDialogFormProps {
  scroll?: "body" | "paper";
  maxWidth?: Breakpoint | false;
  openDialog: boolean;
  // disableEnforceFocus: boolean;
  // disableAutoFocus: boolean;
  title?: string;
  className?: string;
  bgColor?: string;
  color?: string;
  bodyContent?: React.ReactNode;
  paperProps?: Partial<PaperProps>;
  handleDialogClose: () => void;
}

const DialogForm: React.FC<IDialogFormProps> = ({
  scroll,
  maxWidth,
  openDialog,
  handleDialogClose,
  title,
  className,
  bodyContent,
  paperProps,
  bgColor,
  color
  // disableEnforceFocus,
  // disableAutoFocus
}) => {
  return (
    <Dialog
      open={openDialog}
      // onClose={handleDialogClose}
      // id = {"#green-back"}
      onClose={(event, reason) => {
        if (reason !== "backdropClick") {
          handleDialogClose();
        }
      }}
      className={`${className}`}
      scroll={scroll}
      maxWidth={maxWidth}
      PaperProps={paperProps}
      disableAutoFocus
      disableEnforceFocus
    >
      {title && (
        <DialogTitle id="font-600" sx={{ backgroundColor: bgColor ? bgColor : "#38E078", 
        color: color ? color :"#000" }}>
          {/* <Typography>{title}</Typography> */}
          {title}
          <IconButton
            size="small"
            className="close-action"
            onClick={handleDialogClose}

          >
            <Avatar sx={{ width: 22, height: 22 }} 
            style={{color:"#000"}}
            src={images.CloseWhite} />
          </IconButton>
        </DialogTitle>
      )}
      {bodyContent}
    </Dialog>
  );
};

export default DialogForm;
