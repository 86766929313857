import {
  Button,
  Checkbox,
  Grid,
  Typography,
  Radio,
  RadioGroup,
  FormControlLabel,
} from "@mui/material";
import { ErrorMessage, Field, FieldArray, Formik } from "formik";
import React, { Fragment, useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import ShowInputError from "../../shared/components/ShowInputError";
import FormikInput1 from "../../shared/material-ui-formik/FormikInput1";
import { CreateRatesAndAvailabilityValidator } from "../../shared/validations/AdminValidator";
import "react-dropzone-uploader/dist/styles.css";
import { registerPlugin } from "react-filepond";
import "filepond/dist/filepond.min.css";
import FilePondPluginImageExifOrientation from "filepond-plugin-image-exif-orientation";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import Loader from "../../layout/Loader";
import { useTranslation } from "react-i18next";
import { createRecord, fetchList } from "../../apis/services/CommonApiService";
import { ApiEndPoints } from "../../apis/ApiEndPoints";
import moment from "moment";
import { createRatesAndAvailability } from "../../apis/services/ChannelManagerApiService";
import FormikSelect from "../../shared/material-ui-formik/FormikSelect";

// Register the plugins
registerPlugin(FilePondPluginImageExifOrientation, FilePondPluginImagePreview);

const CreateRatesAndAvailability = (props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();

  const [hotelID, setHotelID] = useState("");
  const [roomIdData, setRoomIdData] = useState([]);
  const [roomRateIdData, setRoomRateIdData] = useState([]);

  useEffect(() => {
    getHotelDetailsByID();
    getRoomTypes();
  }, []);

  // const [hotelID, setHotelID] = useState("")
  // const [loading, setLoading] = useState(false);

  // const [roomTypeDataGridOptions, setRoomTypeDataGridOptions] = useState({
  //   loading: false,
  //   rows: [],
  //   totalRows: 0,
  //   rowsPerPageOptions: rowsPerPageJsonData,
  //   pageSize: 10,
  //   page: 1,
  // });

  // useEffect(() => {
  //   getRoomTypes()
  // }, []);

  const getRoomTypes = async () => {
    setLoading(true);
    // get property id
    const userHotelID = localStorage.getItem("userDetails")
      ? JSON.parse(localStorage.getItem("userDetails"))["hotel_id"]
      : 0;
    const result = await fetchList(
      ApiEndPoints.GET_HOTEL_DETAILS + userHotelID
    );

    if (result?.status === 200) {
      setHotelID(result.data.property_id);

      const data = {
        hotelid: result.data.property_id,
      };

      const listing_result = await createRecord(
        data,
        ApiEndPoints.GET_ROOM_TYPE
      );

      // const RoomTypeList = [];

      if (listing_result?.status === 200) {
        const orgResult = listing_result.data.rooms;
        const RoomType = [];

        for (var j in orgResult) {
          RoomType.push({
            id: orgResult[j].roomid,
            roomname: orgResult[j].roomname,
            status: orgResult[j].status,
            roomtype: orgResult[j].roomtype,
            maximumoccupancy: orgResult[j].maximumoccupancy,
            quantity: orgResult[j].quantity,
            rate: orgResult[j].rate,
            NumberOfGuests: "",
          });
        }

        const mainRoomData = await fetchList(
          ApiEndPoints.GET_ROOM_CATEGORY_DATA
        );
        if (mainRoomData?.status === 200) {
          let updatedRoomType = RoomType?.map((item) => ({
            ...item,
            NumberOfGuests: mainRoomData?.data?.find(
              (mItem) => mItem?.room_type_short_code === item?.id
            )?.max_occupancy,
          }));
          setRoomIdData(updatedRoomType);
        } else {
          setRoomIdData(RoomType);
        }

      } else {
        console.log(result.message.response + ", please check!");
      }
      const listing_rate_result = await createRecord(
        data,
        ApiEndPoints.GET_RATE_PLAN
      );
      // const RatePlanList = [];
      if (listing_rate_result?.status === 200) {
        const orgResult = listing_rate_result.data.rateplans;
        const RatePlanObj = [];

        for (var k in orgResult) {
          RatePlanObj.push({
            id: orgResult[k].rateplanid,
            name: orgResult[k].rateplanname,
            status: orgResult[k].status,
            mealplanid: orgResult[k].mealplanid,
          });
        }

        setRoomRateIdData(RatePlanObj);
      } else {
        console.log(listing_rate_result.message.response + ", please check!");
      }
      setLoading(false);
    } else {
      setLoading(false);
      console.log(result.message.response + ", please check!");
    }
  };

  const getHotelDetailsByID = async () => {
    const userHotelID = localStorage.getItem("userDetails")
      ? JSON.parse(localStorage.getItem("userDetails"))["hotel_id"]
      : 0;

    const result = await fetchList(
      ApiEndPoints.GET_HOTEL_DETAILS + userHotelID
    );

    if (result?.status === 200) {
      setHotelID(result.data.property_id);
    } else {
      console.log(result.message.response + ", please check!");
    }
    //
  };

  // console.log(roomRateIdData,156)
  const prices = {
    NumberOfGuests: "",
    value: "",
  };

  const rates = {
    rateplanid: roomRateIdData?.length > 0 ? roomRateIdData[0]?.id : "",
  };

  const roomInfoObj = {
    roomid: "",
    sendAllData: 2,
    showDate: 0,
    value: moment().format("YYYY-MM-DD"),
    from: moment().format("YYYY-MM-DD"),
    to: moment().endOf("month").format("YYYY-MM-DD"),
    rate: [rates],
    roomstosell: 1,
    price: [prices],
    closed: 0,
    minimumstay: 0,
    maximumstay: 0,
    closedonarrival: 0,
    extraadultrate: 0,
    extrachildrate: 0,
    closedondeparture: 0,
  };

  const initialState = {
    hotelID: hotelID,
    room_info: [roomInfoObj],
  };

  const [loading, setLoading] = React.useState(false);

  const handleSubmit = async (values, { resetForm }) => {
    setLoading(true);
    // console.log(values, 189)

    let roomInfoPayload = [];
    let tmpBindObj = {};
    let priceInfoPayload = [];
    let tmpPrice = {};
    let rateInfoPayload = [];
    let tmpRate = {};
    values.room_info.forEach((element, key) => {
      element.rate.forEach((element1, key1) => {
        tmpRate = {
          rateplanid: element1.rateplanid,
        };
        rateInfoPayload.push(tmpRate);
        tmpRate = {};
      });

      element.price.forEach((element1, key1) => {
        tmpPrice = {
          NumberOfGuests: element1.NumberOfGuests,
          value: element1.value,
        };
        priceInfoPayload.push(tmpPrice);
        tmpPrice = {};
      });

      tmpBindObj = {
        roomid: element.roomid,
        rate: rateInfoPayload,
        price: priceInfoPayload,
        closed: element.closed,
        minimumstay: element.minimumstay,
        maximumstay: element.maximumstay,
        // maximumstay: element.maximumstay,
        closedonarrival: element.closedonarrival,
        extraadultrate: element.extraadultrate,
        extrachildrate: element.extrachildrate,
        closedondeparture: element.closedondeparture,
        showDate: element.showDate,
        sendAllData: element.sendAllData,
      };

      Number(element.showDate) === 0
        ? (tmpBindObj.value = element.value)
        : (tmpBindObj.from = element.from);
      tmpBindObj.to = element.to;

      if (+element.sendAllData !== 3) {
        tmpBindObj.roomstosell = element.roomstosell;
      }

      roomInfoPayload.push(tmpBindObj);
      tmpBindObj = {};
      priceInfoPayload = [];
      rateInfoPayload = [];
    });

    const payload = {
      hotelid: values.hotelID,
      rooms: roomInfoPayload,
    };

    const result = await createRatesAndAvailability(payload);

    if (result?.status === 200) {
      toast.success(t(result.message));

      setLoading(false);
      if (props?.handleRatesAndAvailabilityDialog) {
        props?.handleRatesAndAvailabilityDialog();
      } else {
        navigate("/channal-management/");
      }
      resetForm({ values: "" });
    } else if (result === undefined) {
      // let message = result
      // console.log(171, "if", message)
      setLoading(false);
      toast.error(t(result.message));
    } else {
      setLoading(false);
      // let message = (result.message.Errors[0].ShortText)
      // console.log(171 , message)
      if (result?.message?.Errors?.length > 0) {
        toast.error(t(result.message.Errors[0].ShortText));
      } else {
        toast.error(t(result.message));
      }
    }
  };

  return (
    <>
      {loading && <Loader />}
      <div className=" pl-2 pr-2 pb-2 mt-3" style={{ height: "auto" }}>
        <Formik
          initialValues={initialState}
          onSubmit={handleSubmit}
          validateOnBlur={false}
          validateOnChange={true}
          enableReinitialize={true}
          validationSchema={()=>CreateRatesAndAvailabilityValidator(t)}
        >
          {(props) => {
            const { handleSubmit } = props;
            return (
              <form onSubmit={handleSubmit} noValidate>
                <Grid container spacing={2}>
                  <Grid
                    item
                    lg={props?.values?.room_info[0]?.sendAllData === 3 ? 6 : 4}
                    md={props?.values?.room_info[0]?.sendAllData === 3 ? 6 : 4}
                    sx={{ paddingX: 2 }}
                  >
                    <Field
                      label={t("Hotel Id")}
                      name="hotelID"
                      id="hotelID"
                      component={FormikInput1}
                      type="text"
                      readOnly={true}
                    />
                    <ErrorMessage name="hotelID" component={ShowInputError} />
                  </Grid>
                  {/* <Grid className="mt-1"> */}
                  <FieldArray name="room_info">
                    {({ push, remove }) => (
                      // <Grid
                      //   spacing={2}
                      //   sx={{paddingX: 2 }}
                      // >
                      <>
                        {props?.values.room_info.map((_, index) => (
                          <React.Fragment key={index}>
                            {
                              <>
                                {index !== 0 && (
                                  <Grid item lg={12}>
                                    <div
                                      style={{
                                        display: "flex",
                                        justifyContent: "end",
                                        marginRight: "20px",
                                        marginBottom: "20px",
                                        borderTop: "2px solid",
                                        marginTop: "20px",
                                      }}
                                    ></div>
                                  </Grid>
                                )}
                                {/* // <Grid container className="mt-1" spacing={2}> */}
                                {/* <Grid item lg={6} spacing={2}>
                                    <Field
                                      label={t("Room ID")}
                                      placeholder="Room ID"
                                      name={`room_info.[${index}].roomid`}
                                      type="text"
                                      // onChange ={(event) => {console.log(event, props)}
                                      component={FormikInput1}
                                    />
                                    <ErrorMessage
                                      name={`room_info.${index}.roomid`}
                                      component={ShowInputError}
                                    />
                                  </Grid> */}
                                <Grid
                                  item
                                  lg={
                                    props?.values?.room_info[index]
                                      ?.sendAllData === 3 && index === 0
                                      ? 6
                                      : props?.values?.room_info[index]
                                          ?.sendAllData === 3 && index !== 0
                                      ? 12
                                      : index !== 0
                                      ? 6
                                      : 4
                                  }
                                  md={
                                    props?.values?.room_info[index]
                                      ?.sendAllData === 3 && index === 0
                                      ? 6
                                      : props?.values?.room_info[index]
                                          ?.sendAllData === 3 && index !== 0
                                      ? 12
                                      : index !== 0
                                      ? 6
                                      : 4
                                  }
                                >
                                  <Typography
                                  id="font-600"
                                    style={{
                                      marginBottom: "11px",
                                      color: "rgb(30, 30, 30)",
                                      fontSize: "16px",
                                    }}
                                  >
                                    {t("Room Category")}
                                  </Typography>
                                  <Field
                                    label={t("Room Category")}
                                    placeholder={t("Enter Room Category")}
                                    name={`room_info.${index}.roomid`}
                                    id={`room_info.${index}.roomid`}
                                    component={FormikSelect}
                                    // type="text"
                                    onChange={(e) => {
                                      let { value } = e.target;
                                      props.setFieldValue(
                                        `room_info[${index}].roomid`,
                                        value
                                      );
                                      props.setFieldValue(
                                        `room_info[${index}].price[0].value`,
                                        roomIdData?.find(
                                          (iteRoom) => iteRoom?.id === value
                                        )?.rate
                                      );
                                      props.setFieldValue(
                                        `room_info[${index}].price[0].NumberOfGuests`,
                                        roomIdData?.find(
                                          (iteRoom) => iteRoom?.id === value
                                        )?.NumberOfGuests
                                      );
                                    }}
                                    options={roomIdData?.map((data) => ({
                                      title: data?.roomname,
                                      value: data?.id,
                                    }))}
                                  />
                                  <ErrorMessage
                                    name={`room_info.${index}.roomid`}
                                    component={ShowInputError}
                                  />
                                </Grid>
                                {props?.values?.room_info[index]
                                  ?.sendAllData !== 3 && (
                                  <Grid
                                    item
                                    lg={index !== 0 ? 6 : 4}
                                    md={index !== 0 ? 6 : 4}
                                    className=""
                                  >
                                    <Field
                                      label={t("Room to sell")}
                                      placeholder={t("Room to sell")}
                                      name={`room_info.${index}.roomstosell`}
                                      type="number"
                                      component={FormikInput1}
                                    />
                                    <ErrorMessage
                                      name={`room_info.${index}.roomstosell`}
                                      component={ShowInputError}
                                    />
                                  </Grid>
                                )}
                                <Grid item lg={4} md={4}>
                                  <RadioGroup
                                    aria-labelledby={`room_info.${index}.sendAllData`}
                                    name={`room_info.${index}.sendAllData`}
                                    id={`room_info.${index}.sendAllData`}
                                    value={
                                      props?.values?.room_info[index]
                                        ?.sendAllData
                                    }
                                    onChange={(e) => {
                                      Number(e.target.value) === 1
                                        ? props.setFieldValue(
                                            `room_info[${index}].sendAllData`,
                                            1
                                          )
                                        : Number(e.target.value) === 2
                                        ? props.setFieldValue(
                                            `room_info[${index}].sendAllData`,
                                            2
                                          )
                                        : props.setFieldValue(
                                            `room_info[${index}].sendAllData`,
                                            3
                                          );
                                    }}
                                  >
                                    <Typography
                                    id="font-600"
                                      style={{
                                        marginBottom: "11px",
                                        color: "rgb(30,30,30)",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {t("Select Data")}
                                    </Typography>
                                    <FormControlLabel
                                      value={1}
                                      control={<Radio />}
                                      label={t("Send All Data")}
                                      style={{ width: "fit-content" }}
                                    />
                                    <FormControlLabel
                                      value={2}
                                      control={<Radio />}
                                      label={t("Send Only Availability Data")}
                                      style={{ width: "fit-content" }}
                                    />
                                    <FormControlLabel
                                      value={3}
                                      control={<Radio />}
                                      label={t("Send Only Rate Data")}
                                      style={{ width: "fit-content" }}
                                    />
                                  </RadioGroup>
                                </Grid>
                                <Grid item lg={4} md={4}>
                                  <RadioGroup
                                    aria-labelledby={`room_info.${index}.showDate`}
                                    name={`room_info.${index}.showDate`}
                                    id={`room_info.${index}.showDate`}
                                    value={
                                      props?.values?.room_info[index]?.showDate
                                    }
                                    onChange={(e) => {
                                      Number(e.target.value) === 0
                                        ? props.setFieldValue(
                                            `room_info[${index}].showDate`,
                                            0
                                          )
                                        : props.setFieldValue(
                                            `room_info[${index}].showDate`,
                                            1
                                          );
                                    }}
                                  >
                                    <Typography
                                    id="font-600"
                                      style={{
                                        marginBottom: "11px",
                                        color: "rgb(30,30,30)",
                                        fontSize: "16px",
                                      }}
                                    >
                                      {t("Select Date or Date Range")}
                                    </Typography>
                                    <FormControlLabel
                                      value={0}
                                      control={<Radio />}
                                      label={t("Select Date")}
                                      style={{ width: "fit-content" }}
                                    />
                                    <FormControlLabel
                                      value={1}
                                      control={<Radio />}
                                      label={t("Date Range")}
                                      style={{ width: "fit-content" }}
                                    />
                                  </RadioGroup>
                                </Grid>
                                <Grid
                                  item
                                  lg={4}
                                  md={4}
                                  className={
                                    props.values.room_info[index].showDate === 1
                                      ? "dsplNone"
                                      : ""
                                  }
                                >
                                  <Field
                                    label={t("Date")}
                                    placeholder="Select date"
                                    name={`room_info.${index}.value`}
                                    type="date"
                                    component={FormikInput1}
                                  />
                                  <ErrorMessage
                                    name={`room_info.${index}.value`}
                                    component={ShowInputError}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  lg={2}
                                  md={2}
                                  className={
                                    props.values.room_info[index].showDate === 0
                                      ? " dsplNone"
                                      : ""
                                  }
                                >
                                  <Field
                                    label={t("From Date")}
                                    placeholder="From Date"
                                    name={`room_info.${index}.from`}
                                    type="date"
                                    component={FormikInput1}
                                  />
                                  <ErrorMessage
                                    name={`room_info.${index}.from`}
                                    component={ShowInputError}
                                  />
                                </Grid>
                                <Grid
                                  item
                                  lg={2}
                                  md={2}
                                  className={
                                    props.values.room_info[index].showDate === 0
                                      ? " dsplNone"
                                      : ""
                                  }
                                >
                                  <Field
                                    label={t("To Date")}
                                    placeholder="To Date"
                                    name={`room_info.${index}.to`}
                                    type="date"
                                    component={FormikInput1}
                                  />
                                  <ErrorMessage
                                    name={`room_info.${index}.to`}
                                    component={ShowInputError}
                                  />
                                </Grid>
                                {(props?.values?.room_info[index]
                                  ?.sendAllData === 1 ||
                                  props?.values?.room_info[index]
                                    ?.sendAllData === 3) && (
                                  <>
                                    <Grid item lg={6} md={6} className="mt-1">
                                      <Typography
                                      id="font-600"
                                        style={{
                                          marginBottom: "11px",
                                          color: "rgb(30, 30, 30)",
                                          fontSize: "16px",
                                        }}
                                      >
                                        {t("Rate Plan ID")}
                                      </Typography>
                                      <FieldArray
                                        name={`room_info.[${index}].rate`}
                                      >
                                        {({ push, remove }) => (
                                          <Grid
                                            // spacing={2}
                                            //  sx={{ paddingX: 2 }}
                                          >
                                            {props.values.room_info[
                                              index
                                            ].rate.map((_, idx) => (
                                              <Fragment key={idx}>
                                                {
                                                  <Grid container className="">
                                                    <Grid item lg={12} md={12}>
                                                      <Field
                                                        label={t(
                                                          "Rate Plan ID"
                                                        )}
                                                        placeholder={t(
                                                          "Rate Plan ID"
                                                        )}
                                                        name={`room_info.${index}.rate.${idx}.rateplanid`}
                                                        id={`room_info.${index}.rate.${idx}.rateplanid`}
                                                        component={FormikSelect}
                                                        // type="text"
                                                        options={roomRateIdData?.map(
                                                          (data) => ({
                                                            title: data?.name,
                                                            value: data?.id,
                                                          })
                                                        )}
                                                      />
                                                      <ErrorMessage
                                                        name={`room_info.${index}.rate.${idx}.rateplanid`}
                                                        component={
                                                          ShowInputError
                                                        }
                                                      />
                                                    </Grid>
                                                    {/* {idx > 0 ? (
                                                    <Grid
                                                      item
                                                      lg={1.5}
                                                      md={1.5}
                                                      className=" ml-3 
                                                      
                                                       p-1 w-100"
                                                    >
                                                      <Button
                                                        variant="contained"
                                                        color="error"
                                                        size="large"
                                                        onClick={() =>
                                                          remove(idx)
                                                        }
                                                        style={{
                                                          width: "100%",
                                                          height: "100%",
                                                        }}
                                                      >
                                                        <RemoveIcon />
                                                      </Button>
                                                    </Grid>
                                                  ) : (
                                                    <Grid
                                                      item
                                                      lg={1.5}
                                                      md={1.5}
                                                      className=" ml-3 p-1 w-100"
                                                    >
                                                      <Button
                                                        variant="contained"
                                                        size="large"
                                                        style={{
                                                          width: "100%",
                                                          height: "100%",
                                                        }}
                                                        onClick={() =>
                                                          push(rates)
                                                        }
                                                      >
                                                        <AddIcon />
                                                      </Button>
                                                    </Grid>
                                                  )} */}
                                                  </Grid>
                                                }
                                              </Fragment>
                                            ))}
                                          </Grid>
                                        )}
                                      </FieldArray>
                                    </Grid>

                                    <Grid item lg={6} md={6} className="mt-1">
                                      <Typography id="font-600">

                                      {t("Prices")}
                                      </Typography>
                                      <FieldArray
                                        name={`room_info.${index}.price`}
                                      >
                                        {({ push, remove }) => (
                                          <Grid
                                            // spacing={2}
                                            //  sx={{ paddingX: 2 }}
                                          >
                                            {props.values.room_info[
                                              index
                                            ].price.map((_, idx) => (
                                              <Fragment key={idx}>
                                                {
                                                  <Grid
                                                    container
                                                    spacing={2}
                                                    className=""
                                                  >
                                                    <Grid
                                                      item
                                                      lg={6}
                                                      md={6}

                                                      // className="mr-1"
                                                    >
                                                      <Field
                                                        labelN={
                                                          t("Number Of Guests")
                                                        }
                                                        // placeHolder="Number Of Guests"
                                                        name={`room_info.${index}.price.${idx}.NumberOfGuests`}
                                                        type="text"
                                                        component={FormikInput1}
                                                      />
                                                      <ErrorMessage
                                                        name={`room_info.${index}.price.${idx}.NumberOfGuests`}
                                                        component={
                                                          ShowInputError
                                                        }
                                                      />
                                                    </Grid>
                                                    <Grid
                                                      item
                                                      lg={6}
                                                      md={6}
                                                      className=""
                                                    >
                                                      <Field
                                                        labelN={t("Price")}
                                                        // placeholder="Value"
                                                        name={`room_info.${index}.price.${idx}.value`}
                                                        type="text"
                                                        component={FormikInput1}
                                                      />
                                                      <ErrorMessage
                                                        name={`room_info.${index}.price.${idx}.value`}
                                                        component={
                                                          ShowInputError
                                                        }
                                                      />
                                                    </Grid>
                                                    {/* {idx > 0 ? (
                                                    <Grid
                                                      item
                                                      lg={1.5}
                                                      md={1.5}
                                                      className="mt-3 ml-2 p-1 w-100"
                                                    >
                                                      <Button
                                                        variant="contained"
                                                        color="error"
                                                        size="large"
                                                        style={{
                                                          width: "100%",
                                                          height: "100%",
                                                        }}
                                                        onClick={() =>
                                                          remove(idx)
                                                        }
                                                      >
                                                        <RemoveIcon />
                                                      </Button>
                                                    </Grid>
                                                  ) : (
                                                    <Grid
                                                      item
                                                      lg={1.5}
                                                      md={1.5}
                                                      className="mt-3 ml-2 p-1 w-100"
                                                    >
                                                      <Button
                                                        variant="contained"
                                                        size="large"
                                                        style={{
                                                          width: "100%",
                                                          height: "100%",
                                                        }}
                                                        onClick={() =>
                                                          push(prices)
                                                        }
                                                      >
                                                        <AddIcon />
                                                      </Button>
                                                    </Grid>
                                                  )} */}
                                                  </Grid>
                                                }
                                              </Fragment>
                                            ))}
                                          </Grid>
                                        )}
                                      </FieldArray>
                                    </Grid>

                                    <Grid item lg={4} md={4} className="">
                                      <Typography
                                      id="font-600"
                                        style={{
                                          marginBottom: "11px",
                                          color: "rgb(30, 30, 30)",
                                          fontSize: "16px",
                                        }}
                                      >
                                        {t("Closed")}
                                      </Typography>
                                      <label>
                                        <Field
                                          className="solo-check"
                                          name={`room_info.${index}.closed`}
                                          // id="facilities"
                                          type="checkbox"
                                          // value={item.Group + "||" + item.name}
                                          label={"Closed"}
                                          onChange={(e) => {
                                            e.target.checked
                                              ? props.setFieldValue(
                                                  `room_info[${index}].closed`,
                                                  1
                                                )
                                              : props.setFieldValue(
                                                  `room_info[${index}].closed`,

                                                  0
                                                );
                                          }}
                                          component={Checkbox}
                                        />
                                        {t("Closed")}
                                      </label>

                                      <ErrorMessage
                                        name={`room_info.${index}.closed`}
                                        component={ShowInputError}
                                      />
                                    </Grid>
                                    <Grid item lg={4} md={4} className="">
                                      <Typography
                                      id="font-600"
                                        style={{
                                          marginBottom: "11px",
                                          color: "rgb(30, 30, 30)",
                                          fontSize: "16px",
                                        }}
                                      >
                                        {t("Closed on arrival")}
                                      </Typography>
                                      <label>
                                        <Field
                                          className="solo-check"
                                          name={`room_info.${index}.closedonarrival`}
                                          // id="facilities"
                                          type="checkbox"
                                          // value={item.Group + "||" + item.name}
                                          label={t("Closed on arrival")}
                                          onChange={(e) => {
                                            e.target.checked
                                              ? props.setFieldValue(
                                                  `room_info.${index}.closedonarrival`,
                                                  1
                                                )
                                              : props.setFieldValue(
                                                  `room_info.${index}.closedonarrival`,
                                                  0
                                                );
                                          }}
                                          component={Checkbox}
                                        />
                                        {t("Closed on arrival")}
                                      </label>

                                      <ErrorMessage
                                        name={`room_info.${index}.closedonarrival`}
                                        component={ShowInputError}
                                      />
                                    </Grid>
                                    <Grid item lg={4} md={4} className="">
                                      <Typography
                                      id="font-600"
                                        style={{
                                          marginBottom: "11px",
                                          color: "rgb(30, 30, 30)",
                                          fontSize: "16px",
                                        }}
                                      >
                                        {t("Closed on departure")}
                                      </Typography>
                                      <label>
                                        <Field
                                          className="solo-check"
                                          name={`room_info.${index}.closedondeparture`}
                                          // id="facilities"
                                          type="checkbox"
                                          // value={item.Group + "||" + item.name}
                                          label={"Closed on departure"}
                                          onChange={(e) => {
                                            e.target.checked
                                              ? props.setFieldValue(
                                                  `room_info.${index}.closedondeparture`,
                                                  1
                                                )
                                              : props.setFieldValue(
                                                  `room_info.${index}.closedondeparture`,
                                                  0
                                                );
                                          }}
                                          component={Checkbox}
                                        />
                                        {t("Closed on departure")}
                                      </label>

                                      <ErrorMessage
                                        name={`room_info.${index}.closedondeparture`}
                                        component={ShowInputError}
                                      />
                                    </Grid>
                                    <Grid item lg={3} md={3} className="">
                                      <Field
                                        label={t("Minimum Stay")}
                                        placeholder="Minimum Stay"
                                        name={`room_info.${index}.minimumstay`}
                                        type="text"
                                        component={FormikInput1}
                                      />
                                      <ErrorMessage
                                        name={`room_info.${index}.minimumstay`}
                                        component={ShowInputError}
                                      />
                                    </Grid>
                                    <Grid item lg={3} md={3} className="">
                                      <Field
                                        label={t("Maximum Stay")}
                                        placeholder="Maximum Stay"
                                        name={`room_info.${index}.maximumstay`}
                                        type="text"
                                        component={FormikInput1}
                                      />
                                      <ErrorMessage
                                        name={`room_info.${index}.maximumstay`}
                                        component={ShowInputError}
                                      />
                                    </Grid>

                                    <Grid item lg={3} md={3} className="">
                                      <Field
                                        label={t("Extra adult rate")}
                                        placeholder="Extra adult rate"
                                        name={`room_info.${index}.extraadultrate`}
                                        type="text"
                                        component={FormikInput1}
                                      />
                                      <ErrorMessage
                                        name={`room_info.${index}.extraadultrate`}
                                        component={ShowInputError}
                                      />
                                    </Grid>
                                    <Grid item lg={3} md={3} className="">
                                      <Field
                                        label={t("Extra child rate")}
                                        placeholder="Extra child rate"
                                        name={`room_info.${index}.extrachildrate`}
                                        type="text"
                                        component={FormikInput1}
                                      />
                                      <ErrorMessage
                                        name={`room_info.${index}.extrachildrate`}
                                        component={ShowInputError}
                                      />
                                    </Grid>
                                  </>
                                )}

                                <Grid item lg={12}>
                                  <div
                                    style={{
                                      display: "flex",
                                      justifyContent: "end",
                                      marginRight: "20px",
                                      marginBottom: "20px",
                                      // borderTop: "2px solid",
                                      marginTop: "20px",
                                    }}
                                  >
                                    {index ===
                                      props?.values?.room_info?.length - 1 && (
                                      <Button
                                        className="mt-1 comman-button"
                                        size="large"
                                        variant="contained"
                                        onClick={() => push(roomInfoObj)}
                                        color="primary"
                                        // style={{
                                        //   justifyContent: "center",
                                        //   width: "98px",
                                        //   height: "44px",
                                        //   textTransform: "capitalize",
                                        //   background:
                                        //     "linear-gradient(180deg, #255480 0%, #173450 100%)",
                                        // }}
                                      >
                                        {t("Add")}
                                      </Button>
                                    )}
                                    {index > 0 && (
                                      <Button
                                        className="mt-1 ml-1 comman-cancel-button"
                                        size="large"
                                        variant="contained"
                                        onClick={() => remove(index)}
                                        color="primary"
                                        // style={{
                                        //   justifyContent: "center",
                                        //   width: "98px",
                                        //   height: "44px",
                                        //   marginLeft: "18px",
                                        //   textTransform: "capitalize",
                                        //   background:
                                        //     "linear-gradient(180deg, #255480 0%, #173450 100%)",
                                        // }}
                                      >
                                        {t("Remove")}
                                      </Button>
                                    )}
                                  </div>
                                </Grid>

                                {/* </Grid>   */}
                              </>
                            }
                          </React.Fragment>
                        ))}
                        {/* </Grid> */}
                      </>
                    )}
                  </FieldArray>
                </Grid>
                <div
                  style={{
                    display: "flex",
                    justifyContent: "end",
                    marginRight: "20px",
                    marginBottom: "20px",
                    borderTop: "2px solid",
                    marginTop: "20px",
                  }}
                >
                  <Grid item xs={2}>
                    <Button
                      className="mt-1 comman-button"
                      size="large"
                      variant="contained"
                      type="submit"
                      color="primary"
                      // style={{
                      //   justifyContent: "center",
                      //   width: "98px",
                      //   height: "44px",
                      //   textTransform: "capitalize",
                      //   background:
                      //     "linear-gradient(180deg, #255480 0%, #173450 100%)",
                      // }}
                    >
                      {t("Save")}
                    </Button>
                    <Button
                      className="mt-1 ml-1 comman-cancel-button"
                      disabled={!props?.dirty}
                      onClick={() => props?.resetForm()}
                      type="reset"
                      size="large"
                      variant="contained"
                      disableElevation
                      color="primary"
                      // style={{
                      //   justifyContent: "center",
                      //   width: "fit-content",
                      //   height: "44px",
                      //   textTransform: "capitalize",
                      //   background: "#757474",
                      //   marginLeft: "15px",
                      // }}
                    >
                      {t("Clear All")}
                    </Button>
                  </Grid>
                </div>
                {/* </Grid> */}
              </form>
            );
          }}
        </Formik>
      </div>
    </>
  );
};

export default CreateRatesAndAvailability;
