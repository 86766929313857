


import { Autocomplete, Button, FormControl, Grid, Paper, TextField, Typography } from "@mui/material";
import DialogForm from "../../../shared/components/DialogForm";
import { useTranslation } from "react-i18next";
import PhoneInput from "react-phone-input-2";
import { useRef, useState } from "react";
import { createRecord, fetchList } from "../../../apis/services/CommonApiService";
import { ApiEndPoints } from "../../../apis/ApiEndPoints";
import { toast } from "react-toastify";
import { suggestedHotelListFailed, suggestedHotelListSuccess } from "../../../Reducer/roomRateSlice";
import { connect } from "react-redux";


const AddCompetitorHotelDialog = (propsDia) => {
  const { t, i18n } = useTranslation();

    let {openAddHotelDialog,
        setOpenAddHotelDialog,
        suggestedHotelListSuccess,
        suggestedHotelListFailed
    } = propsDia

    const SampleList = [
        {
        competitor_hotel_name: "The Lenox Hotel, Boston, Massachusetts",
        competitor_hotel_id:"114134"
        },
        {
        competitor_hotel_name: "The Black Swan Inn Lee - Lenox, Ascend Hotel Collection, Lee, Massachusetts",
        competitor_hotel_id:"1146989"
        },
        {
        competitor_hotel_name: "The Lenox Guesthouse, Cape Town Central, South Africa",
        competitor_hotel_id:"4698434"
        },
        {
        competitor_hotel_name: "The Don CeSar, St. Pete Beach, Florida",
        competitor_hotel_id:"85782"
        },
        {
        competitor_hotel_name: "Enclave Di",
        competitor_hotel_id:"5"
        },        

];

const autocompleteRef = useRef(null);

const CustomPaper = (props) => {
    return <Paper elevation={8} {...props} />;
  };

  const [selectedHotel, setSelectedHotel] = useState({
    competitor_hotel_name: "",
    competitor_hotel_id: ""
  });

  const [searchHotelText, setSearchHotelText] = useState('');
  const [hotelListData, setHotelListData] = useState([]);
  const [loading, setLoading] = useState(false);


  const onSearchInputChangeHotel = (event) => {
    if (event === null) {
        setSearchHotelText('');
    } else {
      const { value } = event.target;
      if (value) {
        // console.log(value, 59)
        setSearchHotelText(value);
      } 
    }
  };

  const [open, setOpen] = useState(false)

  const getHotelList = async () => {
    // setHotelListData(SampleList);
    //       setOpen(true);

    setLoading(true);
    const result = await fetchList(
        ApiEndPoints.GET_COMPETITOR_HOTEL_BY_SEARCH +
        `?search_text=${searchHotelText}`
      );
      if(result?.status === 200){
          setHotelListData(result?.data?.map((item)=>({competitor_hotel_name: item?.name,
          competitor_hotel_id: item?.document_id})));
            setLoading(false);
          setOpen(true);
      } else {
        setLoading(false);
        toast.error(t(result?.message));
      }
  };

  const handleAddHotel = async () => {
    setLoading(true);

    const result = await createRecord(selectedHotel, ApiEndPoints.ADD_SUGGESTED_HOTEL);
    if(result?.status === 200){
        setLoading(false);
        toast.success(t(result?.message));
        getHotelListNew();
        setOpenAddHotelDialog(false);

    } else {
        setLoading(false);
        toast.error(t(result?.message));
        setOpenAddHotelDialog(false);
    }
  }

  const getHotelListNew = async ()=> {
    const result = await fetchList(
        ApiEndPoints.GET_SUGGESTED_HOTEL_LIST +
        `?page_no=${1}&items_per_page=${10}`
      );
      if (result?.status === 200) {
        suggestedHotelListSuccess(result.data.rows);
      } else {
        suggestedHotelListFailed();
        
      }
    }


    return (
        <DialogForm
        scroll="paper"
        // maxWidth="lg"
        maxWidth="md"
        title={t("Add Compeitor Hotel")}
        openDialog={openAddHotelDialog}
        handleDialogClose={()=> setOpenAddHotelDialog(false)}
        bodyContent={
          <div className="p-2" >
            <Grid container spacing={2} alignItems="flex-start">
            <Grid item lg={8} md={8} sm={8} xs={8}>
            <Typography
              id="font-600"
                  style={{
                    marginBottom: "11px",
                    color: "rgb(30, 30, 30)",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                >
                  {t("Search Hotel")}
                </Typography>
                              <FormControl fullWidth>
                                <Autocomplete
                                  id="combo-box-demo"
                                  options={hotelListData}
                                  value={selectedHotel}
                                  open={open}
                                  onOpen={() => {
                                    setOpen(true);
                                  }}
                                  onClose={() => {
                                    setOpen(false);
                                  }}
                                  onInputChange={onSearchInputChangeHotel}
                                  onChange={(event, value) =>
                                    setSelectedHotel(value)
                                  }
                                  getOptionLabel={(option) =>
                                    option?.competitor_hotel_name  || ''
                                  }
                                  PaperComponent={CustomPaper}
                                  renderInput={(params) => (
                                    <TextField
                                      {...params}
                                      label={t("Search by hotel name")}
                                      variant="outlined"
                                    />
                                  )}
                                />
                              </FormControl>
                            </Grid>
              <Grid item lg={4} md={4} style={{ top: "48px" }}>
                

                <Button 
                variant="contained" 
                disableElevation
                color="primary"
                className="comman-button ml-1"
                // style={{
                //   justifyContent: "center",
                //   width: "auto",
                // //   height: "44px",
                //   textTransform: "capitalize",
                //   marginLeft: "2px",
                //   background:
                //     "linear-gradient(180deg, #255480 0%, #173450 100%)",
                // }}
                onClick={getHotelList}
                > {t("Search")} </Button>
               {/* </div> */}
              </Grid>
              <Grid item lg={12} md={12}>
                <Typography
                  style={{
                    marginBottom: "11px",
                    color: "rgb(30, 30, 30)",
                    fontSize: "16px",
                    lineHeight: "24px",
                  }}
                ></Typography>
               
              </Grid>
              <Grid item lg={12}>
                <Button
                  className="mt-1 comman-button"
                  size="large"
                  variant="contained"
                  onClick={handleAddHotel}  
                  disableElevation
                  color="primary"
                  // style={{
                  //   justifyContent: "center",
                  //   width: "98px",
                  //   height: "44px",
                  //   textTransform: "capitalize",
                  //   background:
                  //     "linear-gradient(180deg, #255480 0%, #173450 100%)",
                  // }}
                >
                  {t("Submit")}
                </Button>
              </Grid>
            </Grid>
          </div>
        }
      />
    )
}


const mapStateToProps = (state) => {
    return {
      suggestedHotelList: state.roomRate.suggestedHotelList,
    };
  };
  
  const mapDispatchToProps = (dispatch, ownProps) => {
    return {
      suggestedHotelListSuccess: (data) => dispatch(suggestedHotelListSuccess(data)),
      suggestedHotelListFailed: () => dispatch(suggestedHotelListFailed()),
    };
  };
  export default connect(mapStateToProps, mapDispatchToProps)(AddCompetitorHotelDialog);
