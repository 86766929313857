// import OwnerLogin from "../../pages/Owner/OwnerLogin/OwnerLogin";

const PATH_PREFIX = process.env.REACT_APP_PATH_PREFIX;

export const Routing = {
  /* Developer Routes */
  Initial: `/`,
  Login: `/login`,
  LoginHotelDetails: `/hotel-details`,
  Dashboard: `/dashboard`,
  Reservation: `/reservation`,
  NonAssignedRooms: `/non-assigned-rooms`,
  ReservationGuestView: `/reports/guest-reservation-list/:id`,
  NewBooking: `/new-booking`,
  SellInvoice: `/sell-invoice`,
  RoomRates: `/roomrates`,
  Guest: `/guest`,
  NewGuest: `/guest/new`,
  EditGuest: `/guest/edit/:id`,
  Calendar: `/calendar`,
  GroupBooking: `/GroupBooking`,

  //reports
  Reports: `/reports`,
  VacantRoomList: `/reports/vacant-room-list`,
  ArrivalList: `/reports/arrival-list`,
  CheckInList: `/reports/checkin-list`,
  CheckOutList: `/reports/checkout-list`,
  RoomAvailabilityList: '/reports/room-availability-list',
  HouseKeepingList: '/reports/house-keeping-list',

  GuestProfileList: '/report/guest-profile-list',
  DepartureList: `/reports/departure-list`,
  RevenueBySourceList: '/reports/revenue-by-source',
  CancellationList: `/reports/cancellation-list`,
  InHouseList: `/reports/in-house-list`,
  InHouseListOld: `/reports/in-house-list-old`,

  NoShowList: `/reports/no-show-list`,
  GuestsList: `/reports/guests-list`,
  RevenueByRoomTypesList: `/reports/revenue-by-room-type-list`,
  RevenueByRoomTypesListNew: `/reports/revenue-by-room-type-list-new`,
  AverageDailyRateList: `/reports/average-daily-rate-list`,
  RateList: "/reports/rate-list",
  HotalStatisticsList: `/reports/hotal-statistics-list`,
  FlashList: "/reports/flash-list",
  ADRRevparList: "/reports/adr-revpar-list",
  ForecastList: "/reports/forecast-list",


  ViewGuest : "/guest/view-guest/:guestId",

  HouseKeeping: `/housekeeping`,
  HouseKeepingNew: `/housekeepingNew`,
  Admin: `/admin`,
  Accounting: `/accounting`,
  CashBook: `/cashbook`,
  Setting: `/setting`,
  HotelList: `/hotels`,
  CreateHotel: `/hotel-create`,
  EditHotel: `/edit-hotel/:hotelId`,
  ViewHotel: `/view-hotel/:hotelId`,
  ViewLoginHotel: `/view-login-hotel/:hotelId`,
  EditRoomCategory: `/edit-room-category/:roomCatId`,
  AddRoomCategory: `/add-room-category`,
  AddRoom: `/add-room`,
  EditRoom: `/edit-room/:roomId`,
  CreateRoomRates: `/create-room-rates`,
  EditRoomRates: `/edit-room-rates/:rateId`,
  EditRoomRatesDateWise:`/edit-room-rates-datewise`,

  DiscountedRate: `/discounted-rate/:id`,
  AddDiscountedRate: `/discounted-rate/new`,
  EditDiscountedRate: `/discounted-rate/edit/:rateId`,

  //group information
  GroupInformation: `/group-information`,
  AddGroupInformation: `/group-information/add`,
  EditGroupInformation: `/group-information/edit/:id`,

  WalkIn: `/walk-in`,

  CreateSeasonalRates: `/create-seasonal-room-rates`,
  EditSeasonalRoomRates: `/edit-seasonal-room-rates/:rateId`,

  CreateBookingRestriction: `/create-booking-restriction`,
  EditBookingRestriction: `/edit-booking-restriction/:restrictionId`,

  Products: "/products",
  NewProducts: "/products/new",
  EditProducts: "/products/edit/:id",
  NewPOSAddOn: "/pos-addon/new",
  EditPOSAddOn: "/pos-addon/edit/:id",



  ProductTypes: "/producttypes",
  NewProductTypes: "/product-types/new",
  EditProductTypes: "/product-types/edit/:typeId",

  Tax: "/tax",
  NewTax: "/tax/new",
  EditTax: "/tax/edit/:taxId",

  Vendors: "/vendors",
  NewVendor: "/vendors/new",
  EditVendor: "/vendors/edit/:id",

  Sale: "/sale",
  Expense: "/expense",
  SalesList: `/saleslist`,
  VisitorSalesList : `/visitorsaleslist`,
  VisitorViewSales : `/visitorsaleslist/:room_no`,
  ViewSales: `/viewsales/:bookingid/:room_no`,
  PrintPOSsales: `/print-invoice-pos/:bookingid/:room_no`,
  ViewReservation: "/reservation/bookingDetail/:id",
  ViewReservationFolio: "/reservation/folioDetail/:id",
  EditReservation:"/reservation/edit/:id",

  CompanyProfile: "/companyprofile",
  NewCompanyProfile: "/companyprofile/new",
  EditCompanyProfile: "/companyprofile/edit/:id",

  Lock: "/lock",
  ViewLock: "/lock/view-lock",
  ViewLockRecords: "/lock/view-lock-records",

  ChannelManagement: "/channal-management",
  RoomRate: "/channal-management/room-rates/:channelId/:propertyId",
  Availability: "/channal-management/availability/:channelId/:propertyId",
  IframeCall: "/iframe-call",
  AddOta: "/add-ota",
  EditOta: "/edit-ota/:id",
  RoomType: "/channal-management/room-type",
  CreateRoomType: "/channal-management/add-room-type/:roomTypeID",
  RatePlan: "/channal-management/rate-plan",
  CreateRatePlan: "/channal-management/add-rate-plan/:ratePlanID",
  CreateRatesAndAvailability: "/channal-management/add-rates-and-availability",

  NightReports: "/night-reports",
  NightReportsList: "/reports",
  // /night-reports/list",

  // Data Migration

  DataMigration: "/data-migration",

  HotelJournalSummary: "/reports/night-reports/hotel-journal-summary",
  ShiftReconciliationCloseout: "/reports/night-reports/shift-Reconciliation-closeout",
  NightAuditNoShowReport: "/reports/night-reports/night-audit-no-show-report",
  TaxExemptReportStats: "/reports/night-reports/tax-exempt-report-stats",
  FinalTransactionCloseout: "/reports/night-reports/final-transaction-closeout",
  NightAuditHotelStatastics: "/reports/night-reports/night-audit-hotel-statastics",
  CheckInNightReport: "/reports/night-reports/check-in-night-report",
  CheckOutNightReport: "/reports/night-reports/check-out-night-report",
  LedgerSummary: "/reports/night-reports/ledger-summary",
  PrePaidReservations: "/reports/night-reports/pre-paid-reservations",
  InHouseListNew: "/reports/night-reports/in-house-list-new",
  FlashNightList: "/reports/night-reports/flash-list",

  Terms: "/about/terms",
  Privacy: "/about/privacy",
  Contact: "/about/contact",

  NewAddOn: "/add-On/new",
  EditAddOn: "/add-On/edit/:id",

  NewOccCri: "/occ-rate/new",
  EditOccCri: "/occ-rate/edit/:id",

  NewSpeCri: "/spe-rate/new",
  EditSpeCri: "/spe-rate/edit/:id",
  NewMinStayCri: "/min-stay-cri/new",
  EditMinStayCri: "/min-stay-cri/edit/:id",
  NewBookingEngine: "/booking-engine/new",
  EditBookingEngine: "/booking-engine/edit/:id",
  NewCouponCode: "/coupon-code/new",
  EditCouponCode: "/coupon-code/edit/:id",
  EditSmsTemplate: "/communication-templates-sms/edit/:id",
  EditEmailTemplate: "/communication-templates-email/edit/:id",


  Roles:"/roles",

  Agreement:"/agreement/:BookingId",
  SuccessAgreement:"/agreement/success",
  SuccessBoking: "/direct-booking/:id/success",

  // Ouner
  OwnerDashBoard: "/owner-dashboard",
  OwnerLogin: "/owner-login",
  OwnerReport: "/owner-reports",
  HotelWiseOwnerReport: "/owner-reports/hotel-owner-reports",
  OwnerIframeReport: "/owner-frame-report",


  Module:"/module",
  NewModule:"/module/new",
  EditModule:"/module/edit/:moduleId",

  Popular_Places:"/popular-places",
  NewPopular_Places:"/popular-places/new",
  EditPopular_Places:"/popular-places/edit/:placeId",

  // Destinations
  Destination:"/destination",
  NewDestination:"/destination/new",
  EditDestination:"/destination/edit/:destinationId",

  Owners: "/owners",
  NewOwners:"/owners/new",
  EditOwners:"/owners/edit/:ownersId",

  // Property

  NewProperty:"/property/new",

  AdminUsers:"/admin-users",
  AddAdminUsers:"/admin-users/new",
  EditAdminUsers:"/admin-users/edit/:adminUserId",
  Shift:"/shift",
  NewShift:"/shift/new",
  EditShift:"/shift/edit/:shiftId",

  CalendarRoom:"/calendarRoom",

  // chat
  Chat: "/chat",
  DirectChat: "/direct-chat",
  DirectBooking: "/direct-booking/:hotel_id",
  DirectBookingNew: "/direct-bookings",
  DirectCheckIn: "/guest-checkin/:hotel_id",

  //invoice
  Invoice: "/create-invoice/:invoice_id",
  OtaOverviewNew: "/ota-overview-new",

  //Kitchen Dashboard
  KitchenDashboard: "/kitchen-dashboard",
};
