import DialogForm from "../../../shared/components/DialogForm";
import { useTranslation } from "react-i18next";
import { useCallback, useEffect, useState } from "react";
import {  fetchList } from "../../../apis/services/CommonApiService";
import { ApiEndPoints } from "../../../apis/ApiEndPoints";
import { toast } from "react-toastify";
import { Button, FormControl, Grid, MenuItem, Select, Table, TableBody, TableCell, TableHead, TableRow, TextField, Typography } from "@mui/material";
import moment from "moment";
import Loader from "../../../layout/Loader";



const CompetitorHotelRatesDialog = (propsDia) => {
  const { t, i18n } = useTranslation();

    let {openHotelRatesDialog,
        setOpenHotelRatesDialog,
        hotelId,
        hotelName
    } = propsDia




  const [loading, setLoading] = useState(false);
  const [hotelListRate,setHotelListRate] = useState([]);
  const [searchFilters, setSearchFilters] = useState({
    startDate: moment().add(1, "days").format("YYYY-MM-DD"),
    endDate: moment().add(2, "days").format("YYYY-MM-DD"),
    noOfGuest: 2,
    rooms:1
  });

  const getHotelRate = async () => { 
    // setHotelListData(SampleList);
    //       setOpen(true);

    setLoading(true);
    const result = await fetchList(
        ApiEndPoints.GET_COMPETITOR_HOTEL_RATE + `?checkin=${searchFilters?.startDate}&checkout=${searchFilters?.endDate}&rooms=${searchFilters?.rooms}&adults=${searchFilters?.noOfGuest}&hotelid=${hotelId}`
      );
      if(result?.status === 200){
        let array = []
        for(let i in result?.data?.comparison){
          for(let j in result?.data?.comparison[i]){
            let obj = result?.data?.comparison[i][j]
            // console.log(obj[`vendor${+j+1}`],j, 44)
            array.push({
              vendor: obj[`vendor${+j+1}`],
              price: obj[`price${+j+1}`],
              tax: obj[`tax${+j+1}`],
            })
          }
        }
          setHotelListRate(array);
          setLoading(false);
      } else {
        setLoading(false);
        toast.error(t(result?.message));
      }
  };

  // console.log(hotelListRate,59)

const handleCheckOutDate = (event) => {
    const { value } = event.target;
    // setEndDate(value);
    setSearchFilters((prevState) => ({
      ...prevState,
      endDate: value,
    }));
}

const handleCheckInDate = (event) => {
    const { value } = event.target;
    // setStartDate(value);
    setSearchFilters((prevState) => ({
      ...prevState,
      startDate: value,
      endDate: moment(moment(value, "YYYY-MM-DD").add({ days: 1 })).format(
        "YYYY-MM-DD"
      ),
    }));


}

const handleGuestChange = (event) => {
  const { value } = event.target;
  // setNoOfGuest(value);
  setSearchFilters((prevState) => ({
    ...prevState,
    noOfGuest: value,
  }));
};

const handleRoomChange = (event) => {
  const { value } = event.target;
  // setNoOfGuest(value);
  setSearchFilters((prevState) => ({
    ...prevState,
    rooms: value,
  }));
}


    return (
        <DialogForm
        scroll="paper"
        maxWidth="lg"
        // maxWidth="md"
        title={`${t("View Hotel Rates")} (${hotelName})`}
        openDialog={openHotelRatesDialog}
        handleDialogClose={()=> {setOpenHotelRatesDialog(false);
                                setHotelListRate([]);
                            }}
        bodyContent={
          <div className="p-2" >
            {loading && <Loader />}
            <Grid container spacing={2} >
            <Grid item lg={4} md={4} sm={4} xs={4} style={{ marginBottom: "20px" }}>
            <FormControl fullWidth>
              <Typography
                style={{
                  marginBottom: "11px",
                  color: "rgb(30, 30, 30)",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 500,
                }}
              >
                {t("Check In Date")}
              </Typography>
              <TextField
                type="date"
                inputProps={{ min: moment().add(1,'days').format("YYYY-MM-DD") }}
                value={searchFilters?.startDate}
                onChange={handleCheckInDate}
              />
            </FormControl>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4} style={{ marginBottom: "20px" }}>
            <FormControl fullWidth>
              <Typography
                style={{
                  marginBottom: "11px",
                  color: "rgb(30, 30, 30)",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 500,
                }}
              >
                {t("Check Out Date")}
              </Typography>
              <TextField
                type="date"
                inputProps={{
                  min: moment(
                    moment(searchFilters.startDate, "YYYY-MM-DD").add({
                      days: 1,
                    })
                  ).format("YYYY-MM-DD"),
                }}
                value={searchFilters.endDate}
                onChange={handleCheckOutDate}
              />
            </FormControl>
          </Grid>
          <Grid item lg={2} style={{ marginBottom: "20px" }}>
            <FormControl fullWidth>
              <Typography
                style={{
                  marginBottom: "11px",
                  color: "rgb(30, 30, 30)",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 500,
                }}
              >
                {t("Adults")}
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={1}
                onChange={handleGuestChange}
                value={searchFilters?.noOfGuest}
              >
                {Array.from({ length: 10 }, (_, i) => i + 1).map((data) => (
                  <MenuItem key={data} value={data}>{data}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={2} style={{ marginBottom: "20px" }}>
            <FormControl fullWidth>
              <Typography
                style={{
                  marginBottom: "11px",
                  color: "rgb(30, 30, 30)",
                  fontSize: "16px",
                  lineHeight: "24px",
                  fontWeight: 500,
                }}
              >
                {t("Rooms")}
              </Typography>
              <Select
                labelId="demo-simple-select-label"
                id="demo-simple-select"
                defaultValue={1}
                onChange={handleRoomChange}
                value={searchFilters?.rooms}
              >
                {Array.from({ length: 10 }, (_, i) => i + 1).map((data) => (
                  <MenuItem key={data} value={data}>{data}</MenuItem>
                ))}
              </Select>
            </FormControl>
          </Grid>
          <Grid item lg={4} md={4} sm={4} xs={4} style={{ marginBottom: "20px" }}>
          <Button
            variant="contained" 
            disableElevation
            color="primary"
            className="comman-button ml-1"
            onClick={getHotelRate}
            > {t("Submit")} </Button>
          </Grid>
          <Grid item lg={12} md={12} sm={12} xs={12}>
              <Table>
              <TableHead>
                <TableRow>
                  <>
                    <TableCell className="text-center" width="50">
                      <Typography variant="h4"> {t("OTA")}</Typography>
                    </TableCell>
                    <TableCell className="text-center" width="50">
                      <Typography variant="h4"> {t("Rate")}</Typography>
                    </TableCell>
                    <TableCell className="text-center" width="50">
                      <Typography variant="h4"> {t("Tax")}</Typography>
                    </TableCell>
                  </>
                </TableRow>
              </TableHead>
              <TableBody >
                {
                hotelListRate?.map((item, it)=>{
                 
                    return (
                      <TableRow key={it}>
                        <>
                          <TableCell className="text-center" width="50">
                            {item[`vendor`]}
                          </TableCell>
                          <TableCell className="text-center" width="50">
                            {item[`price`]}
                          </TableCell>
                          <TableCell
                            className="text-center"
                            width="50"
                          //   style={{
                          //     wordWrap: "break-word",
                          //     whiteSpace: "normal",
                          //   }}
                          >
                            {item[`tax`]}
                          </TableCell>
                        </>
                      </TableRow>
                    );
                  
                })
                
               }
              </TableBody>
            </Table>
            </Grid>
            </Grid>
    
          </div>
        }
      />
    )
};

export default CompetitorHotelRatesDialog;



